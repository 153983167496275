export default function inject_csrf_to_forms() {
  const csrf_token = document.querySelector('head meta[name="csrf-token"]').content;
  const csrf_input = `<input type="hidden" name="authenticity_token" value="${csrf_token}" />`;

  document.querySelectorAll('form').forEach((form) => {
    const auth_input = form.querySelector('input[type="hidden"][name="authenticity_token"]');

    if (!auth_input) {
      console.log('CSRF Injected');
      form.insertAdjacentHTML('afterbegin', csrf_input);
    }
  });
}

document.addEventListener('DOMContentLoaded', inject_csrf_to_forms);
