// Import images
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', true, /\.(gif|png|jpe?g|svg)$/))

/*---------------------------------------------------------------------------*/

import M from 'materialize-css';

import '../styles/materialize';
import '../styles/navigation';
import '../styles/header';
import '../styles/main';

import '../scripts/csrf_form_injector';
import '../scripts/auto_fill';

document.addEventListener('DOMContentLoaded', function() {
  M.AutoInit();
  
  // Version panel.
  var versionSwitchElementClickCount = 0;
  var versionSwitchElement = document.getElementsByClassName("version-switch");
  var versionPanel = document.getElementById("version-panel");
  
  if (versionSwitchElement.length > 0 && versionPanel) {
    versionSwitchElement[0].addEventListener("click", function(e) {
      if (e.shiftKey) {
        versionSwitchElementClickCount++;
      } else {
        versionSwitchElementClickCount = 0;
      }
      // console.log(versionSwitchElementClickCount);
      
      if (versionSwitchElementClickCount > 0 && versionSwitchElementClickCount > 5) {
          versionPanel.style.display = "block";
      }
    }, false);
  }
});
