function autoFillWindow() {
  const element = document.querySelector('.auto-fill');
  if (element === null) return;

  element.style.setProperty('height', `${window.innerHeight - 64 - 24}px`);
  element.style.setProperty('width', `${window.innerWidth - 300 - 24}px`);
}

document.addEventListener('DOMContentLoaded', autoFillWindow);
window.addEventListener('resize', autoFillWindow);
